import ViewMeta from "./ViewMeta";

export default function Winner() {
  return (
    <>
      <div>
        <h2>Winner page</h2>
      </div>
      <ViewMeta page={"WINNER"} />
    </>
  );
}
