import Helmet from "react-helmet";
import { META } from "./../config/constants";

export default function ViewMeta(props: any) {
  const { page } = props;
  return (
    <Helmet>
      <title>{META[page].PAGE_TITLE}</title>
      <meta name="title" content={META[page].META_TITLE} />
      <meta
        name="description"
        content={META[page].META_DESCRIPTION}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="#WendySSquarevivorPool" />
      <meta property="og:title" content={META[page].META_TITLE} />
      <meta property="og:description" content="Tap or scan to join in!" />
      <meta property="og:url" content="https://google.com" />
      <meta property="og:image" content="https://fastly.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI" />
    </Helmet>
  );
}
