import ViewMeta from "./ViewMeta";

export default function About() {
  return (
    <>
      <div>
        <h2>About</h2>
      </div>
      <ViewMeta page={"ABOUT"} />
    </>
  );
}
