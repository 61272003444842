export const META = {
  HOME: {
    PAGE_TITLE: "Wenndys home page title",
    META_TITLE: "home, title, homepage",
    META_DESCRIPTION: "this is home page description",
  },
  ABOUT: {
    PAGE_TITLE: "About page title",
    META_TITLE: "About, title, About page",
    META_DESCRIPTION: "this is About page description",
  },
  WINNER: {
    PAGE_TITLE: "Winner page title",
    META_TITLE: "Winner, title, Winner page",
    META_DESCRIPTION: "this is Winner page description",
  },
  NOTFOUND: {
    PAGE_TITLE: "404 page title",
    META_TITLE: "NotFound, NotFound page",
    META_DESCRIPTION: "this is NotFound page description",
  },
} as any;
