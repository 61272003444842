import { Routes, Route } from "react-router-dom";

import Layout from "./components/Layout";
import Home from "./components/Home";
import About from "./components/About";
import Winner from "./components/Winner";
import NotFound from "./components/NotFound";

export default function App() {
  return (
    <div className="wrap">
      <div className="container">
        <h1>Wendys Squarevivor Pool POC</h1>

        <p>#WendySSquarevivorPool A fresh new way to play March Madness.</p>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="winner" element={<Winner />} />

            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
}
